(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Programme
   *
   * @description
   *
   */
  angular
  .module('neo.services')
  .factory('Programmes', Programmes);

  function Programmes($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/programmes');
  }
}());
